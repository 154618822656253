import type { AutocompleteRecord } from '~/server/api/attributes/autocomplete-data.get'

const key = (key: string) => `data-${key}`

export function useAutocompleteData() {
  const data = useState(key('data'), () => ([]) as AutocompleteRecord[])
  const isLoading = useState(key('loading'), () => false)
  const last = useState(key('last'), () => 'none')

  async function fetchData(zipCode?: string, range?: string) {
    const newValue = `${zipCode}-${range}`
    if ((last.value === newValue) || isLoading.value)
      return

    isLoading.value = true
    last.value = newValue
    data.value = []

    try {
      const params = { zip: zipCode, range }
      data.value = await $fetch('/api/attributes/autocomplete-data', {
        query: { ...params, 'x-cs': 'autocomplete' },
      })
    }
    catch (error) {
      console.error(error)
    }
    finally {
      isLoading.value = false
    }
  }

  return {
    data,
    isLoading,
    fetchData,
  }
}

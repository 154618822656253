import deepEqual from 'fast-deep-equal'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { DecodedParams } from '~/composables/searchPageData'
import type { SapiListings } from '~/types/CarstoryApiTypes'

const relevantParams = ['make', 'model', 'body_style'] as const
type RelevantParamsKeys = typeof relevantParams[number]
type LastSearch = {
  [K in RelevantParamsKeys]?: string
}

export const useLastSearchStore = defineStore('LastSearch', () => {
  const { currentRoute } = useRouter()
  const routeName = computed(() => currentRoute.value.name)
  const range = computed(() => currentRoute.value.query.range)
  const { location } = toRefs(useVisitorStore())
  const zipCode = computed(() => location.value?.zip)
  const decodedParams = useSearchDecodedParams()
  const expires = new Date(Date.now() + 30 * 86400 * 1000) // Expires in 30 days
  const lastSearch = useCookie<LastSearch>('previous-search', {
    expires,
  })
  if (!lastSearch.value || typeof lastSearch.value !== 'object')
    lastSearch.value = {} // in case cookie was corrupted

  const hasLastSearch = computed(() => relevantParams.some(param => !!lastSearch.value[param]))

  /// ///// assigning last search values
  watchEffect(() => {
    if (currentRoute.value.name === 'search' && decodedParams.value) {
      const newLastSearch = extractLastSearch(toValue(decodedParams))
      if (newLastSearch && !deepEqual(newLastSearch, lastSearch.value))
        nextTick(() => { lastSearch.value = newLastSearch }).catch()
    }
  })/////////////////////////////

  type ResultTuple = [params: Record<string, string | boolean>, count: number, tpl: string]
  const resultData = reactive<ResultTuple[]>([
    [{ newlylisted: true }, 0, 'Recently added %'],
    [{ price: 'min-20000' }, 0, 'Browse % under $20K'],
    [{ badge_labels: 'Great Deal' }, 0, 'Browse deals on %'],
  ])

  const loading = ref(false)
  const lastCommonParams = ref({})

  const fetchData = async () => {
    await useAsyncData('last-search-data', async () => {
      const commonParams = { range: range.value, zip: zipCode.value, limit: 0, ...lastSearch.value }
      const shouldFetch = (!deepEqual(lastCommonParams.value, commonParams))
        && (hasLastSearch.value && routeName.value === 'home')

      if (shouldFetch) {
        loading.value = true
        const promises = []
        for (const [params] of resultData) {
          const query = Object.assign({}, commonParams, params, { 'x-cs': 'lastSearchStore' })
          promises.push($fetch('/api/listings/search-results', { query }))
        }
        lastCommonParams.value = Object.assign({}, commonParams)
        const res = await Promise.all(promises)
        loading.value = false
        return res
      }
      return Promise.resolve([])
    }, {
      transform(results: SapiListings[]) {
        for (const [index, item] of results.entries())
          resultData[index][1] = item?.matches ?? 0
        return results
      },
    })
  }

  return {
    fetchData,
    hasLastSearch,
    loading,
    lastSearch,
    results: resultData,
    lastCommonParams,
  }
})

/**
 * Extracts params to be recorded as last search
 * @param decodedParams
 */
function extractLastSearch(decodedParams: DecodedParams): LastSearch | null {
  const { make, model, body_style } = decodedParams
  const map = new Map()
  if (make)
    map.set('make', `${make}`)
  if (model)
    map.set('model', `${model}`)
  if (!map.size && body_style?.length && !body_style.includes(','))
    map.set('body_style', `${body_style}`)
  return map.size ? Object.fromEntries(map.entries()) : null
}

// code to enable HMR
if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useLastSearchStore, import.meta.hot))

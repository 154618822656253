<script setup lang="ts">
import type { AutocompleteRecord } from '~/server/api/attributes/autocomplete-data.get'
import type { VueMultiselect } from '~/types/vue-multiselect'
import { Multiselect } from 'vue-multiselect'

const props = withDefaults(defineProps<Props>(), {
  clearOnSelect: true,
  closeOnSelect: true,
  freeTextSearch: false,
  preserveSearch: true,
  excludeBodyStyle: false,
  navigateOnSelect: true,
  resetAfter: true,
  placeholder: 'Search by make, model, or body style',
  zipCode: '',
})

const emit = defineEmits(['searchChange', 'open', 'close', 'select'])

export interface Props {
  clearOnSelect?: boolean
  closeOnSelect?: boolean
  freeTextSearch?: boolean
  preserveSearch?: boolean
  excludeBodyStyle?: boolean
  navigateOnSelect?: boolean
  resetAfter?: boolean
  placeholder?: string
  zipCode?: string
}

const route = useRoute()
const router = useRouter()
const { data, isLoading: loading, fetchData } = useAutocompleteData()

const select = ref<VueMultiselect<unknown>>()
const value = ref('')
const searchQuery = ref('')

function onOpen() {
  fetchAutocompleteData()
}

function fetchAutocompleteData() {
  fetchData(props.zipCode, route.query.range as string || '')
}

const options = computed(() => {
  if (loading.value || !data.value?.length)
    return []
  const options = getMatchingOptions(searchQuery.value, data.value)
  return Object.values(options)
})

function onSearchChange(search: string) {
  searchQuery.value = search.trim().replace(/\s+/g, ' ')
}

type AutocompleteOption = AutocompleteRecord & { name: string }

function onSelect(option: AutocompleteOption, id: string) {
  const params: Record<string, string> = {}
  if (props.zipCode)
    params.zip = props.zipCode
  if (route.query.range)
    params.range = route.query.range as string
  if (option.type === 'make') {
    params.make = option.value
  }
  else if (option.type === 'model') {
    params.model = option.value
    params.make = option.make as string
  }
  else if (option.type === 'body_style') {
    params.body_style = option.value
  }

  emit('select', option, id, searchQuery.value, params)

  if (props.navigateOnSelect) {
    const builder = new UrlBuilder('search', params)
    router.push(builder.href()).catch()
    nextTick(() => {
      const element = select.value?.$el as HTMLElement
      if (element)
        element.focus()
    })
  }
}

function getMatchingOptions(search: string, apiData: AutocompleteRecord[]) {
  apiData = toValue(apiData)
  const out = {
    make: {
      type: 'Make',
      values: [] as AutocompleteOption[],
    },
    model: {
      type: 'Model',
      values: [] as AutocompleteOption[],
    },
    body_style: {
      type: 'Body Style',
      values: [] as AutocompleteOption[],
    },
  }

  const currentSearchToLower = search.toLowerCase()
  const searchableFields: (keyof AutocompleteRecord)[] = [
    'searchableBy',
    'searchableByAlias',
    'alias',
    'value',
  ]

  const isMatchingCurrentValue = (value: unknown) => {
    return typeof value === 'string'
      && value.toLowerCase().startsWith(currentSearchToLower)
  }
  if (currentSearchToLower) {
    apiData.forEach((option) => {
      if (searchableFields.some(field => isMatchingCurrentValue(option[field]))) {
        const resultOption: AutocompleteOption = { ...option, name: option.searchableBy || option.value }
        if (props.excludeBodyStyle && resultOption.type === 'body_style')
          return
        out[resultOption.type].values.push(resultOption)
      }
    })
  }
  return out
}

const shouldHideContentWrapper = computed(() => {
  return !searchQuery.value.length
    || !(select.value?.filteredOptions?.length)
})
</script>

<template>
  <Multiselect
    ref="select"
    v-bind="props"
    v-model="value"
    :class="{ 'cs-hide-content-wrapper': shouldHideContentWrapper }"
    :options="options"
    group-values="values"
    group-label="type"
    :internal-search="false"
    :reset-after="resetAfter"
    open-direction="bottom"
    :placeholder="placeholder"
    label="name"
    track-by="name"
    :loading="loading"
    :show-no-results="!!searchQuery"
    :clear-on-select="clearOnSelect"
    @search-change="onSearchChange"
    @keydown.enter="() => 0"
    @open="onOpen"
    @select="onSelect"
  >
    <template #noResult>
      <div class="cs-no-results">
        No results found for your Search
      </div>
    </template>
  </Multiselect>
</template>
